<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img class="mx-auto h-24 w-auto" src="../assets/cb-login-logo.png" alt="botics logo" />
    <h6 class="uppercase tracking-widest mt-5 text-center text-lg">Build Applications. Fast.</h6>
  </div>
</template>

<script>
export default {
  name: "ExternalHeader"
}
</script>